import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {Button, Card, Form, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {LoaderSvg} from "../Reusable";

import HOC from '../../HOC';
import moment from "moment/moment";
import {PaymentTypeOptionFilter, SearchTypes,JOB_ADDI_CHARGES_PAYMENT_STATUS} from './Constants';
import Select from 'react-select';
import {DatePicker, Switch} from 'antd';
import AsyncSelect from 'react-select/async';
import Pagination from "react-js-pagination";
import ProductServiceIncident from '../productServiceIncident';
import {
    updateJobStatus,
    updateFromExcel,
    getProductJobListForReport,
    exportProductJobRequestForReport,
    updateAddiPaymentStatus
} from '../../config/services/ProductJob';
import {getAllEngineers, getAllVendors} from "../../config/services/vendorService";
import {getAllStates} from "../../config/services/servicePincodes";
import PopUpModal from "../common/PopUpModal";
import Popup from "reactjs-popup";
import {getNoteList} from "../../config/services/NoteService";

const itemsCountPerPage = 10;
const {RangePicker} = DatePicker;
const {
    getTableHeader, statusOptions, getValue, productServiceTypeOptions, getSortingIcon, paymentTypeOptions
} = require("../utils/Util.js");


const columnDetails = [{fieldName: "S.No.", dataIndex: "index", className: "sticky-tableHeader-fixed", role: true}, {
    fieldName: "Job Creation Date",
    isSortable: true,
    dataIndex: "created",
    type: "dateTime",
    className: "sticky-tableHeader-fixed",
    role: true
}, {
    fieldName: "Request Id", dataIndex: "requestId", type: "string", className: "sticky-tableHeader-fixed", role: true
}, {fieldName: "Job Id", dataIndex: "_id", type: "string", className: "sticky-tableHeader-fixed", role: true}, {
    fieldName: "Order Id",
    parentDataIndex: "requestServiceData",
    dataIndex: "orderId",
    type: "string",
    className: "sticky-tableHeader-fixed",
    role: true
}, {
    fieldName: "Product Service Type ",
    dataIndex: "productServiceType",
    className: "sticky-tableHeader-fixed",
    role: true
}, {
    fieldName: "Status", dataIndex: "status", type: "combo", className: "sticky-tableHeader-fixed", role: true
}, // { fieldName: "Spare Part ETA", isSortable: true, dataIndex: "sparePartEta", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Remarks", dataIndex: "remark", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Is Urgent", dataIndex: "isUrgent", className: "sticky-tableHeader-fixed", role: false },
    // { fieldName: "Is Rescheduled", dataIndex: "isRescheduled", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "TAT Performance", dataIndex: "inTat", className: "sticky-tableHeader-fixed", role: false },
    // { fieldName: "Tat Date", dataIndex: "tatDate", className: "sticky-tableHeader-fixed", role: false },
    {
        fieldName: "Vendor",
        dataIndex: "firstName",
        parentDataIndex: "vendorData",
        className: "sticky-tableHeader-fixed",
        role: false
    }, {
        fieldName: "Associate",
        dataIndex: "firstName",
        parentDataIndex: "associateEngineerData",
        className: "sticky-tableHeader-fixed",
        role: false
    }, {
        fieldName: "Job Close Date",
        dataIndex: "closeDate",
        type: "dateTime",
        className: "sticky-tableHeader-fixed",
        role: true
    }, // { fieldName: "Job Pending Date", dataIndex: "jobPendingDate", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Engineer Schedule", dataIndex: "jobScheduleDateTime", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    {
        fieldName: "Customer Name",
        dataIndex: "customerId",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    }, {
        fieldName: "Customer Email",
        dataIndex: "customerEmail",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    }, // { fieldName: "CultsportAppDownoaded", dataIndex: "isOneFitPlusDownloadbyVendor", className: "sticky-tableHeader-fixed", role: false },
    // { fieldName: "CultsportPlayAppDownloaded", dataIndex: "isFitWarzDownloadbyVendor", className: "sticky-tableHeader-fixed", role: false },
    // { fieldName: "UserStartedLiveSessions", dataIndex: "isTrainerAssigned", className: "sticky-tableHeader-fixed", role: false },
    {
        fieldName: "Customer Contact Number",
        dataIndex: "customerPhNO",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    }, // { fieldName: "Customer Alternate Number", dataIndex: "customerAltPhNO", type: "string", className: "sticky-tableHeader-fixed", role: true },
    {
        fieldName: "Customer Address",
        dataIndex: "customerAddress",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    }, {
        fieldName: "City", dataIndex: "city", type: "string", className: "sticky-tableHeader-fixed", role: true
    }, {
        fieldName: "State", dataIndex: "state", type: "string", className: "sticky-tableHeader-fixed", role: true
    }, {
        fieldName: "PinCode", dataIndex: "pincode", type: "string", className: "sticky-tableHeader-fixed", role: true
    }, {
        fieldName: "Brand", dataIndex: "brand", type: "string", className: "sticky-tableHeader-fixed", role: true
    }, {fieldName: "Model", dataIndex: "model", type: "string", className: "sticky-tableHeader-fixed", role: true},
    {
        fieldName: "Vendor Service Charges",
        dataIndex: "vendorServiceCharges",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    },
    {
        fieldName: "Additional Charges",
        dataIndex: "additionalCharges",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    },

    {
        fieldName: "Additional Charges By Admin",
        dataIndex: "additionalChargesByAdmin",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    },
    {
        fieldName: "Additional Charges Agreed On",
        dataIndex: "additionalChargesOnAgreed",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    },

     {
        fieldName: "Payment Current Status",
        dataIndex: "paymentCurrentStatus",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    }, {
        fieldName: "Payment Status",
        dataIndex: "paymentStatus",
        type: "string",
        className: "sticky-tableHeader-fixed",
        role: true
    }, // { fieldName: "Required Part", dataIndex: "spareReceived", type: "string", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Action", dataIndex: "action", className: "sticky-tableHeader-fixed", role: false },
    // { fieldName: "Images", dataIndex: "images", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Payment Type", dataIndex: "paymentType", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Amount", dataIndex: "jobAmount", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Actual Collected Amount", dataIndex: "jobActualCollectedAmount", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Customer Accepted Fees", dataIndex: "isCustomerAcceptedFee", className: "sticky-tableHeader-fixed", role: true },
    // { fieldName: "Issue", dataIndex: "issue", className: "sticky-tableHeader-fixed", role: true },
    {
        fieldName: "Notes",
        isSortable: true,
        dataIndex: "noteData.notes_unread_count",
        className: "sticky-tableHeader-fixed",
        role: false
    }, // { fieldName: "Internal Notes", isSortable: true, dataIndex: "internalNoteData.notes_unread_count", className: "sticky-tableHeader-fixed", role: true },
];

const formField = [{
    fieldName: "Request Id",
    stateName: "requestId",
    isRequire: true,
    type: "text",
    maxLength: 30,
    invalidMsg: "Request Id can not be empty"
}, // { fieldName: "Job Type", stateName: "jobTypeId", isRequire: true, type: "text", maxLength: 30, invalidMsg: "Job Type is Require" },
    {
        fieldName: "Vendor", stateName: "vendorId", isRequire: true, invalidMsg: "Please select vendor"
    }, {fieldName: "Associate Engineer", stateName: "associateEngineerId"}, {
        fieldName: "Status", stateName: "status", isRequire: true, invalidMsg: "Please select status"
    }

]

const GetJobsForReport = (props) => {

    let {isFromUser} = props
    let isRequestIdLinked = props.history ? true : false;
    let requestId: props.requestId;

    const initialState = {
        apiCalled: false, pageNo: 0, pageSize: 0,
    };

    let role = localStorage.getItem('role')
    const [adminRole, setAdminRole] = useState(role)

    const [loader, setLoader] = useState(true)
    const [statusPopup, setStatusPopup] = useState(false)
    const [apiCalled, setApiCalled] = useState(false)
    const [showAddProductJobNotesPopup, setShowAddProductJobNotesPopup] = useState(false)
    const [showAcceptPaymentPopup, setShowAcceptPaymentPopup] = useState(false)
    const [showRejectPaymentPopup, setShowRejectPaymentPopup] = useState(false)
    const [showProductJobNotesPopup, setShowProductJobNotesPopup] = useState(false)
    const [notesLoader, setNotesLoader] = useState(false)
    const [isFoundErrorNoteMessage, setIsFoundErrorNoteMessage] = useState(false)
    const [showHideExport, setShowHideExport] = useState(false)
    const [enableAddiInputBox, setEnableAddiInputBox] = useState(false)
    // dates related variables
    const [dateFrom, setDateFrom] = useState(moment().startOf('day').valueOf());
    const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf());
    const [closeDateFrom, setCloseDateFrom] = useState(null);
    const [closeDateTo, setCloseDateTo] = useState(null);
    const [pendingDateFrom, setPendingDateFrom] = useState(null);
    const [pendingDateTo, setPendingDateTo] = useState(null);


    const [allstates, setAllStates] = useState([]);
    const [jobNoteLists, setJobNoteLists] = useState([]);
    let [seachStatus, setSeachStatus] = useState([]);
    const [productJobList, setProductJobList] = useState([]);
    const [vendors, setVendors] = useState([]);

    // Filter related variables
    const [appliedStatusFilter, setAppliedStatusFilter] = useState("");
    const [stateFilter, setStateFilter] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [vendorFilterData, setVendorFilterData] = useState("");
    const [filterByTat, setFilterByTat] = useState("");
    const [filterByPaymentType, setFilterByPaymentType] = useState("");
    const [searchField, setSearchField] = useState("");
    const [searchError, setSearchError] = useState("");
    const [vendorId, setVendorId] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [vendorFilter, setVendorFilter] = useState("");
    const [fieldNameData, setFieldNameData] = useState("");
    const [jobIdProp, setJobIdProp] = useState("");

    // const [pageNumber, setPageNumber] = useState(1);

    // const [state, setState] = useState({ ...initialState });
    const [searchValue, setSearchValue] = useState("");
    const [sortField, setSortField] = useState("created");

    const [search, setSearch] = useState('');
    const [selectedJobId, setSelectedJobId] = useState('');
    const [getServiceIncidentFromJobId, setGetServiceIncidentFromJobId] = useState('');
    const [selectedJobForJobId, setSelectedJobForJobId] = useState('');
    const [noteJobId, setNoteJobId] = useState('');
    const [noteMessage, setNoteMessage] = useState('');
    const [noteState, setNoteState] = useState('');
    const [internalNotesType, setInternalNotesType] = useState('');

    const [totalGyms, setTotalGyms] = useState(0);
    const [sortType, setSortType] = useState(-1);
    const [pageNo, setPageNo] = useState(1);
    const [count, setCount] = useState(10);
    const [totalProductJob, setTotalProductJob] = useState(15);
    const [statePageNo, setStatePageNo] = useState(0);
    const [stateCount, setStateCount] = useState(0);
    const [selectedJobStatus, setSelectedJobStatus] = useState(-1);

    const [state, setState] = useState({});
    const [addiChargesSettle, setAddiChargesSettle] = useState({
        jobId: '',
        transport: '',
        courier: '',
        spareparts: '',
        paymentReason :'',
        isAdditionalChargesOnAgreed : false,
    });

    const [addiChargesReject, setAddiChargesReject] = useState({
        jobId: '',
        transport: '',
        courier: '',
        spareparts: '',
        paymentRejectReason :'',
        isAdditionalChargesOnAgreed : false,
        additionalChargesPaymentStatus : 0,
    });


    useEffect(() => { // this will
        getVendors();
        getAllState();
        // get admin role
        let role = localStorage.getItem('role')
        setAdminRole(role)

    }, []);


    useEffect(() => {
        getProductJobList()
    }, [searchField, searchValue, search, dateFrom, dateTo, closeDateFrom, closeDateTo, pendingDateTo, pendingDateFrom, filterStatus, appliedStatusFilter, filterByTat, filterByPaymentType, pageNo, vendorFilterData, stateFilter])

    useEffect(() => {
        getProductJobList()
    }, [sortField, sortType])


    const handleShowHideExport = () => {
        setShowHideExport(true)
    }

    const closeExportModal = () => {
        setShowHideExport(false)
    }

    const exportHandler = () => {
        setShowHideExport(false);
        setApiCalled(false);
        getProductJobList(true)
    }


    const filterByDate = (date) => {
        let dateFrom = null, dateTo = null, closeDateFrom = null, closeDateTo = null, pendingDateFrom = null,
            pendingDateTo = null;
        if (date) {
            dateFrom = moment(date[0]).startOf('day').valueOf()
            dateTo = moment(date[1]).endOf('day').valueOf()
        }

        setDateFrom(dateFrom);
        setDateTo(dateTo);
    }


    const filterByCloseDate = (date) => {
        let closeDateFrom = null, closeDateTo = null, dateFrom = null, dateTo = null, pendingDateFrom = null,
            pendingDateTo = null;
        if (date) {
            closeDateFrom = moment(date[0]).startOf('day').valueOf()
            closeDateTo = moment(date[1]).endOf('day').valueOf()
        }
        setCloseDateFrom(closeDateFrom);
        setCloseDateTo(closeDateTo)
    }


    const handleFilterChange = (value, fieldName) => {
        if (fieldName == 'filterStatus') {
            let foundAll = 0;
            value && value.length && value.map((item) => {
                if (item.label == "ALL") {
                    foundAll = 1;
                } else {
                    seachStatus.push(item.value);
                }
            });

            if (foundAll == 1) {
                if (value.length > 1) {
                    var lastIndex = value[value.length - 1].label;
                    if (lastIndex == 'ALL') {
                        setSeachStatus([]);
                        value = [];
                        var status = {};
                        status.label = 'ALL';
                        status.value = '';
                        value.push(status);
                    } else {
                        value = value.filter(function (item) {
                            return item.label != 'ALL'
                        })
                    }
                }
            }

            seachStatus = seachStatus.join();
            setAppliedStatusFilter(value);
            // setSeachStatus(seachStatus.join());
        } else {
            setFilterByPaymentType(value)
        }
    }

    const handleStateChange = (value, fieldName, callListApi) => {
        setStateFilter(value);
    }

    const loadVendors = (val) => {
        return getVendors(val)
    }

    const onHandleVendorSelection = (data, fieldName) => {
        setFieldNameData(data);
        setVendorFilterData(data);
        setVendorFilter(data._id);

    }

    const handleSearchType = (search) => {
        setSearchField(search);
        setSearchValue('');
        setSearchError('');
    }

    const handleSearchByEnter = (e) => {
        if (e.target.value.length > 0) {
            if (e.key === 'Enter') {
                getProductJobList();
            }
        }
    }

    const handleSearchData = () => {
        if (!dateFrom && !dateTo && !closeDateFrom && !closeDateTo && !pendingDateFrom && !pendingDateTo && !filterStatus && (!vendorFilterData && !vendorFilterData.value) && !filterByTat && !filterByPaymentType && !searchField && !searchValue) {
            toast.error('Please select any value');
        } else if (!searchField && searchValue) {
            toast.error('Please select search field');
        } else if (searchField && !searchValue) {
            toast.error('Please enter search value');
        } else {
            setApiCalled(true);
            setPageNo(1);
            setSortField("created");
            getProductJobList()
        }
        getCorrectJobId()
    }


    /* const handlePageChange = (pageNo) => {
         setPageNumber(pageNo);
         setState({ ...state, loading: true });
     };*/

    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
        getProductJobList()
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }


    const resetFilter = () => {
        setSearchField(searchField);
        setSearchValue('');
        setPendingDateTo('');
        setPendingDateFrom('');
        setPageNo(1);
        setDateFrom(moment().startOf('day').valueOf());
        setDateTo(moment().endOf('day').valueOf());
        setSearch('');
        setCloseDateFrom('');
        setCloseDateTo('');
        setFilterStatus("");
        setLoader(true)
        setVendorFilterData("");
        setStateFilter('');

        // this.setState({
        //      pendingDateTo:'',pendingDateFrom:'', filterStatus: "", appliedStatusFilter: [], filterByTat: '',filterByPaymentType:'', pageNo: 1, filterStateData: '', Loader: true,
        //     vendorFilterData: "", stateFilter: '', selectedState: []
        // }, () => this.getProductJobList())
    }

    const getProductJobList = (isExport) => {
        let {isFromUser, requestId} = props;

        let paramsObj = {
            params: {
                search,
                count,
                pageNo: pageNo - 1,
                sortType,
                sortField,
                requestId: requestId,
                dateFrom: !isFromUser ? dateFrom ? moment(dateFrom).startOf('day').valueOf() : null : null,
                dateTo: !isFromUser ? dateTo ? moment(dateTo).endOf('day').valueOf() : null : null,
                closeDateFrom: !isFromUser ? closeDateFrom ? moment(closeDateFrom).startOf('day').valueOf() : null : null,
                closeDateTo: !isFromUser ? closeDateTo ? moment(closeDateTo).endOf('day').valueOf() : null : null,
                pendingDateFrom: !isFromUser ? pendingDateFrom ? moment(pendingDateFrom).startOf('day').valueOf() : null : null,
                pendingDateTo: !isFromUser ? pendingDateTo ? moment(pendingDateTo).endOf('day').valueOf() : null : null,
                filterStatus: filterStatus,
                vendorId: vendorFilterData && vendorFilterData.value,
                filterByTat: filterByTat,
                filterByPaymentType: filterByPaymentType,
                searchField: isFromUser && requestId != '' ? 'requestId' : searchField.value,
                searchValue: isFromUser && requestId != '' ? requestId : searchValue.trim(),
                stateFilter: stateFilter ? stateFilter : ''
            }
        }

        setLoader(true);
        setGetServiceIncidentFromJobId('');


        if (isExport) {
            if (productJobList.length > 0) {
                paramsObj.params.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                exportProductJobRequestForReport(paramsObj).then(res => {
                    let response = res.data;
                    if (response.statusCode === 1) {
                        window.open(response.responseData.result.Location, "_blank");
                    } else if (response.statusCode === 0) {
                        toast.error(response.error.errorMessage)
                    }
                    setApiCalled(false)
                    setLoader(false)
                })
            } else {
                setApiCalled(false)
                setLoader(false)
                toast.error("No data available to export")
            }
        } else {

            getProductJobListForReport(paramsObj)
                .then(res => {
                    let response = res.data
                    // var mmm = JSON.parse(JSON.stringify(response));
                    // console.log("response  mmm ---->>  ", mmm);
                    if (response.statusCode === 1) {
                        setLoader(false);
                        setApiCalled(false);
                        if (response.responseData.result.data && response.responseData.result.data[0]) {
                            setGetServiceIncidentFromJobId(response.responseData.result.data[0]._id);
                        } else {
                            setGetServiceIncidentFromJobId('not_found_any_jobs');
                        }
                        setProductJobList(response.responseData.result.data);
                        setTotalProductJob(response.responseData.result.totalCount);

                    } else if (response.statusCode === 0) {
                        toast.error(response.error.errorMessage);
                        setLoader(false);
                        setApiCalled(false);
                    }

                });
        }
    }

    const getVendors = (val) => {
        let paramsobj = {params: {count: 0, pageNo: 0, search: val}}
        return getAllVendors(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.vendors;
                    result && result.map(vendor => {
                        vendors.push({label: `${vendor.firstName} ${vendor.lastName}`, value: vendor._id})
                    })
                    setVendors(vendors)
                    return vendors
                } else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    const getAllState = () => {
        let params = {search: '', sortField: "_id", sortType: 1}
        getAllStates({params})
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    let data = res.data.responseData
                    let totalCount = data.totalCount;
                    let pgno = statePageNo + 1;

                    if (data.result && data.result.length > 0) {
                        data.result.forEach(key => {
                            allstates.push({
                                label: key._id, value: key._id,
                            })
                        })
                    }

                    setAllStates(allstates);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    }

    const getCorrectJobId = () => {
        if (searchField.value === "_id" && searchField.value === SearchTypes.filter((a) => a.value === '_id')[0].value) {
            let jobId = searchField.value === SearchTypes.filter((a) => a.value === '_id')[0].value ? searchValue : selectedJobId
            setJobIdProp(jobId)
        }
    }

    const disableLoader = () => {
        setLoader(false);
    }

    const onJobClick = (jobId) => {
        setLoader(true);
        setTimeout(disableLoader, 1000);
        setGetServiceIncidentFromJobId(jobId);
        setSelectedJobId(jobId);
    }

    const handleParticularJobStatus = (statusValue, id) => {
        setStatusPopup(true);
        setSelectedJobStatus(statusValue);
        setSelectedJobForJobId(id);
    }

    const closeStatusPopup = () => {
        setStatusPopup(false);
        setSelectedJobStatus(-1);
        setSelectedJobForJobId("");
    }

    const changeParticularJobStatus = () => {
        let paramsObj = {
            jobId: selectedJobForJobId, jobStatus: selectedJobStatus
        }
        updateJobStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode === 1) {
                    setSelectedJobForJobId("");
                    setSelectedJobStatus(-1);
                    setStatusPopup(false);
                    getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode === 0) {
                    setLoader(false);
                    toast.error(res.data.error.errorMessage)
                }
            })

    }

    const handleAddProductJobNotesPopup = (jobInfo, notesType) => {
        let currentStatus = statusOptions.find(status => status.value === jobInfo.status);

        setShowAddProductJobNotesPopup(true);
        setNoteJobId(jobInfo._id);
        setNoteMessage("");
        setNoteState(currentStatus && currentStatus.label ? currentStatus.label : '');

        if (notesType === "internalNotes") {
            setInternalNotesType(notesType);
        }
    }

    const handleShowProductJobNotesPopup = (jobInfo, internalNotes) => {
        setShowProductJobNotesPopup(true);
        setJobNoteLists([]);

        if (internalNotes) {
            let paramsobj = {params: {refId: jobInfo._id, internalNotes: true}}
            viewNotes(jobInfo, paramsobj, internalNotes);
        } else {
            let paramsobj = {params: {refId: jobInfo._id}}
            viewNotes(jobInfo, paramsobj);
        }
    }

    const viewNotes = (jobInfo, paramsobj, internalNotes) => {
        getNoteList(paramsobj)
            .then(res => {
                if (res.data.statusCode === 1) {
                    let result = res.data.responseData.result;
                    // this.setState({ jobNoteLists: result })
                    setJobNoteLists(result);

                    if (internalNotes) {
                        if (jobInfo['internalNoteData'] && jobInfo['internalNoteData']['notes_unread_count'] > 0) {
                            jobInfo['internalNoteData']['notes_unread_count'] = 0;
                        }
                    } else {
                        if (jobInfo['noteData'] && jobInfo['noteData']['notes_unread_count'] > 0) {
                            jobInfo['noteData']['notes_unread_count'] = 0;
                        }
                    }

                } else if (res.data.statusCode === 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
            .catch((err) => {
                console.log('error', err);
            })
    }

    const addJobNoteAction = (e) => {
        e.preventDefault();
        let fonndError = false;
        setNotesLoader(false);
        setIsFoundErrorNoteMessage(false);
        const {noteJobId, noteMessage, noteState} = this.state;

        if (noteMessage === '') {
            fonndError = true;
            setIsFoundErrorNoteMessage(true);
        }

        if (!fonndError) {
            setNotesLoader(true);
            let params = {};
            params.refId = noteJobId;
            params.content = noteMessage;
            params.state = noteState;
            if (this.state.internalNotes === "internalNotes") {
                params.internalNotes = true
                this.addNotes(params)
            } else {
                this.addNotes(params)
            }
        }
    }

    const closeModal = () => {
        setShowAddProductJobNotesPopup(false);
        setShowProductJobNotesPopup(false);
    }


    const onClickAccept = (details) => {
        const jobId  = details["_id"];
        const ss = details["additionalCharges"]["addiChargesForSpareParts"];
        const tt = details["additionalCharges"]["addiChargesForTransport"];
        const cc = details["additionalCharges"]["addiChargesForCourier"];

        setShowAcceptPaymentPopup(true);
        setAddiChargesSettle({
            ...addiChargesSettle,
            jobId: jobId,
            spareparts: ss,
            transport: tt,
            courier: cc
        });
    }

    const onClickReject = (details) => {
        const jobId  = details["_id"];
        const ss = details["additionalChargesOnAgreed"]["addiChargesForSpareParts"];
        const tt = details["additionalChargesOnAgreed"]["addiChargesForTransport"];
        const cc = details["additionalChargesOnAgreed"]["addiChargesForCourier"];

        setShowRejectPaymentPopup(true);
        setAddiChargesReject(
            {
                ...addiChargesReject,
                jobId: jobId,
                spareparts: ss,
                transport: tt,
                courier: cc
            }
        );
    }

    const handleChange = (e, type, field, length) => {
        type ? setState({
            ...state, [type]: e, error: ""
        }) : setState({
            ...state,
            [e.target.name]: e.target.value,
            error: e.target.value.length > length ? `${field} has reached max length` : ""
        });
    }

    const handleChangeAddiSettle = (e, type, field, length) => {
        setAddiChargesSettle({
            ...addiChargesSettle, [e.target.name]: e.target.value
        });
    }

    const handleChangeAddiRejection = (e, type, field, length) => {
        setAddiChargesReject({
            ...addiChargesReject, [e.target.name]: e.target.value
        });
    }


    const closeShowAcceptPaymentPopup = () => {
        setShowAcceptPaymentPopup(false)
    }

    const closeShowRejectPaymentPopup = () => {
        setShowRejectPaymentPopup(false)
    }

    const handleAcceptButtonClickOfPopup = () => {
        setShowAcceptPaymentPopup(false);
        handleUpdateAddiPaymentStatusApiCall(true);
    }

    const handleRejectButtonClickOfPopup = () => {
        setShowRejectPaymentPopup(false);
        handleUpdateAddiPaymentStatusApiCall(false);
    }


    /**
     * handleUpdateAddiPaymentStatusApiCall() : this method is responsible for call updateAddiPaymentStatus api
     * with required request param
     * */
    const handleUpdateAddiPaymentStatusApiCall = (isAcceptCall) => {
        let paramsObj
       if (isAcceptCall){
           paramsObj = {
               jobId: addiChargesSettle.jobId,
               additionalChargesPaymentStatus : JOB_ADDI_CHARGES_PAYMENT_STATUS.ACCEPTED,
               isAdditionalChargesOnAgreed: enableAddiInputBox,
               addiChargesForSpareParts: addiChargesSettle.spareparts,
               addiChargesForTransport: addiChargesSettle.transport,
               addiChargesForCourier: addiChargesSettle.courier,
               remark: addiChargesSettle.paymentReason,
           }
       }else {
           paramsObj = {
               jobId: addiChargesReject.jobId,
               additionalChargesPaymentStatus : JOB_ADDI_CHARGES_PAYMENT_STATUS.REJECTED,
               isAdditionalChargesOnAgreed: false,
               addiChargesForSpareParts: 0,
               addiChargesForTransport: 0,
               addiChargesForCourier: 0,
               remark: addiChargesReject.paymentRejectReason,
           }

       }

        setLoader(true)
        updateAddiPaymentStatus(paramsObj)
            .then((res) => {
                setLoader(false);
                if (res.data.statusCode === 1) {
                    setAddiChargesSettle({
                        ...addiChargesSettle,
                        jobId: '',
                        transport: '',
                        courier: '',
                        spareparts: '',
                        paymentReason :'',
                        isAdditionalChargesOnAgreed : false,
                    });

                    getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode === 0) {
                    setLoader(false);
                    toast.error(res.data.error.errorMessage)
                }
            })

    }

    const handleSort = (field) => {
        let orderType = 1;
        if (sortType && sortField === field) {
            orderType = sortType < 0 ? '' : -1;
        }

        setSortType(orderType);
        setSortField(field);
    }

    const handleAddiChargesSettlement = (event) => {
        setEnableAddiInputBox(event.target.checked);

    }


    if (!selectedJobId && productJobList.length > 0) {
        setSelectedJobId(productJobList[0]._id);
    }

    return (<HOC>
        {loader && <div className="loader_wrapper"><LoaderSvg/></div>}
        <div className={!isFromUser ? "body-container-wrapper" : ""}>
            <div className={!isFromUser ? "body-container" : ""}>
                <ol className="breadcrumb" style={{display: isFromUser ? "none" : ""}}>
                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item">Report On Jobs</li>
                </ol>

                {loader && <div className="loader_wrapper"><LoaderSvg/></div>}
                <div className="users_header" style={{display: isFromUser ? "none" : ""}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="cm_page_heading mt-1 mb-0">Report On Jobs</h4>
                            </div>
                            <div className="col-md-6 list-inline-item text-right">
                                {/*{!isFromUser ?
                                        adminRole != 7 ?
                                            <button className="btn btn-primary" onClick={handleAddProductJobPopup}>Add New Job</button>
                                            : '' :
                                        ''}*/}

                                {adminRole != 7 ? <Button disabled={apiCalled} className='ml-1' type="primary"
                                                          onClick={handleShowHideExport}>Export</Button> : ""}

                            </div>

                        </div>
                    </div>
                </div>


                {/*-----------------------options filters---------------------------*/}
                {!isFromUser && <div className="row mb-2">
                    <div className="col-xl-12 align-items-baseline d-inline-flex ">

                        <ul className="d-flex mb-0 flex-wrap">
                            <li className="list-inline-item d-flex flex-column mt-2 range-wrapper-width">
                                <label inline className="mr-2">Filter By Date:</label>
                                <RangePicker
                                    style={{height: "2.8em"}}
                                    size={"middle"}
                                    onChange={(val) => filterByDate(val)}
                                    value={dateFrom && dateTo && [moment(dateFrom), moment(dateTo)]}
                                    defaultValue={[moment(dateFrom), moment(dateTo)]}
                                />
                            </li>

                            <li className="list-inline-item d-flex flex-column mt-2 range-wrapper-width">
                                <label inline className="mr-2">Filter By Close Date:</label>
                                <RangePicker
                                    style={{height: "2.8em"}}
                                    size={"middle"}
                                    name="filterByCloseDate"
                                    onChange={(val) => filterByCloseDate(val)}
                                    value={closeDateFrom && closeDateTo && [moment(closeDateFrom), moment(closeDateTo)]}
                                    defaultValue={[moment(closeDateFrom), moment(closeDateTo)]}
                                />
                            </li>
                            {/*<li className="list-inline-item d-flex flex-column mt-2 range-wrapper-width">
                                        <label inline className="mr-2">Filter By Pending Date:</label>
                                        <RangePicker
                                            style={{ height: "2.8em" }}
                                            size={"middle"}
                                            name="filterByPendingDate"
                                            onChange={(val) => filterByPendingDate(val)}
                                            value={pendingDateFrom && pendingDateTo && [moment(pendingDateFrom), moment(pendingDateTo)]}
                                            defaultValue={[moment(pendingDateFrom), moment(pendingDateTo)]}
                                        />
                                    </li>*/}

                            <li className="list-inline-item mt-2">
                                <label inline className="mr-2">Status:</label>
                                <Select
                                    isMulti
                                    className='cm_min_width'
                                    onChange={(e) => handleFilterChange(e, "filterStatus")}
                                    placeholder="Status"
                                    options={statusOptions}
                                    value={appliedStatusFilter}
                                    classNamePrefix="custom"
                                />
                            </li>

                            <li className="list-inline-item mt-2">
                                <label inline='true' className="mr-2">State:</label>
                                <Select
                                    className='cm_min_width'
                                    onChange={(e) => handleStateChange(e.value, "stateFilter", 1)}
                                    placeholder="Filter By State"
                                    options={allstates}
                                    value={stateFilter ? allstates.find(element => element.value === stateFilter) : null}
                                />
                            </li>
                            {adminRole != 7 ? <>
                                <li className="list-inline-item mt-2">
                                    <label inline className="mr-2">Vendor:</label>
                                    <AsyncSelect
                                        className='cm_min_width'
                                        isDisabled={false}
                                        cacheOptions
                                        value={vendorFilterData}
                                        loadOptions={loadVendors}
                                        onFocus={() => loadVendors()}
                                        defaultOptions={vendors}
                                        onChange={(val) => onHandleVendorSelection(val, "vendorFilter")}
                                        placeholder="Select Vendor"
                                        classNamePrefix="custom"
                                    />
                                </li>
                                <li className="list-inline-item mt-2">
                                    <label inline className="mr-2">Payment Type:</label>
                                    <Select
                                        classNamePrefix="custom"
                                        isSearchable
                                        options={PaymentTypeOptionFilter}
                                        placeholder="Payment Type"
                                        className="cm_min_filter_width"
                                        onChange={(e) => handleFilterChange(e.value, "filterByPaymentType")}
                                        value={filterByPaymentType ? PaymentTypeOptionFilter.find(element => element.value === filterByPaymentType) : ''}
                                    />
                                </li>
                            </> : ""}
                            <li className="list-inline-item mt-2">
                                <label inline className="mr-2">Fields:</label>
                                <Select
                                    classNamePrefix="custom"
                                    isSearchable
                                    options={SearchTypes}
                                    placeholder="Search Field"
                                    className="cm_min_filter_width"
                                    onChange={(val) => handleSearchType(val)}
                                    value={searchField}
                                />
                            </li>
                            <li className="list-inline-item mt-2">
                                <label inline className="mr-2">Search:</label>
                                <FormControl id="search"
                                             className="h-auto" name='search'
                                             value={searchValue} placeholder="Search here......"
                                             onChange={handleSearch}
                                             onKeyPress={handleSearchByEnter}/>
                            </li>

                            <li className="list-inline-item align-self-end">
                                <Button variant="primary"
                                        className="btn-reset"
                                        style={{marginBottom: '2px'}}
                                        size='sm' onClick={handleSearchData}> <i className="fa fa-search"/></Button>
                            </li>
                            <li className="list-inline-item align-self-end">
                                <Button variant="outline-dark"
                                        className="btn-reset"
                                        style={{marginBottom: '2px'}}
                                        size='sm' onClick={resetFilter}> <i className="fa fa-refresh"/></Button>
                            </li>
                        </ul>
                    </div>
                </div>}
                {/*-----------------------options filters---------------------------*/}

                <div className="table-responsive">
                    <table className="table table-bordered table-striped text-center mb-2">
                        <thead>
                        <tr>
                            {columnDetails.map((item, i) => {
                                let {isSortable, dataIndex} = item;
                                return (isSortable && adminRole != 7) || (isSortable && adminRole == 7 && item.role) ?
                                    <th scope="col" key={i} className="filter_text"
                                        onClick={() => handleSort(dataIndex)}>{item.fieldName} &nbsp;  <i
                                        className={getSortingIcon(dataIndex == sortField && sortType)}/>
                                    </th> : (adminRole == 7 && item.role == false) ? '' :
                                        <th scope="col" key={i}>{item.fieldName}</th>
                            })}
                        </tr>
                        </thead>

                        <tbody>
                        {productJobList && productJobList.map((details, i) => {
                            return (<tr key={i}>
                                {columnDetails.map((item) => {
                                    let col = [];
                                    if (item.dataIndex == "index") {
                                        col.push(<td>{((i + 1) + ((pageNo - 1) * count))}</td>)
                                    } else if (item.dataIndex == "productServiceType") {
                                        col.push(
                                            <td>{details["productServiceType"] === 1 ? "INSTALLATION" : details["productServiceType"] === 2 ? "REPAIR" : details["productServiceType"] === 3 ? "UNINSTALLATION" : details["productServiceType"] === 4 ? 'REVISIT' : '-'}</td>)
                                    } else if (item.dataIndex == "paymentType") {
                                        col.push(
                                            <td>{details["paymentType"] === 1 ? "FREE" : details["paymentType"] === 2 ? "PREPAID" : details["paymentType"] === 3 ? "COD" : '-'}</td>)
                                    } else if (item.dataIndex == "isUrgent" && adminRole != 7) {
                                        col.push(<td>
                                            <Switch loading={this.state[`urgentLoading${details["_id"]}`]}
                                                    className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${details["isUrgent"] === true ? 'cm_active' : 'cm_inactive'}`}
                                                    onClick={(e) => this.handleChangeStatusUrgent(details["_id"], details["isUrgent"], e)}
                                                    checked={details["isUrgent"] === true}
                                            >
                                                <span className="cm_ckeckbox_btn"></span>
                                            </Switch>
                                        </td>)
                                    } else if (item.dataIndex == "status") {
                                        col.push(<td className="cm_no_wrap">
                                            {<Select
                                                className={`cm_min_width min_height_new ${adminRole == 7 && 'read-only'}`}
                                                onChange={(e) => handleParticularJobStatus(e.value, details._id)}
                                                placeholder="Select..."
                                                options={statusOptions.filter((item) => item.value)}
                                                value={statusOptions.find(status => status.value === details.status)}
                                                optionClassName="option-AS"
                                                menuPlacement="auto"
                                                classNamePrefix="custom"
                                                isDisabled={adminRole == 7 ? true : false}
                                            />}
                                        </td>)
                                    } else if (item.dataIndex === "requestId") {
                                        col.push(<td>
                                            {(isRequestIdLinked && details.requestServiceData) && (adminRole != 7) &&
                                                <Link
                                                    to={`/service/${details.requestServiceData["userId"]}/productService`}>{details[item.dataIndex]} </Link> || details[item.dataIndex]}
                                        </td>);
                                    } else if (item.dataIndex === "_id") {
                                        col.push(<td><Link to="#"
                                                           onClick={() => onJobClick(details["_id"])}>{details["_id"]}</Link>
                                        </td>);
                                    } else if (item.dataIndex === "isRescheduled") {
                                        col.push(<td
                                            className="cm_no_wrap"> {details["isRescheduled"] && details["isRescheduled"] == true && "True" || "False"}</td>)
                                    } else if (item.dataIndex === "tatDate" && adminRole != 7) {
                                        let dateToRender = moment(details["created"]).add(Number(details["requestServiceData"] && details["requestServiceData"]["pincodeData"] && details["requestServiceData"]["pincodeData"].tat) || 0, "days").format('Do MMM YY, h:mm:ss a');
                                        col.push(<td className="cm_no_wrap"> {dateToRender}</td>)
                                    } else if (item.dataIndex === "inTat" && adminRole != 7) {
                                        let calculteTatDay = moment(details["closeDate"]).diff(details["created"], 'days');
                                        var tat_day = details && details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"].tat : 0;
                                        col.push(<td
                                            className="cm_no_wrap"> {calculteTatDay <= tat_day ? "In Tat" : "Out Tat"}</td>)
                                    } else if (item.dataIndex === "customerId") {
                                        col.push(<td>
                                            {details["customerData"] && details && details["requestServiceData"] ? <span
                                                onClick={() => this.onCustomerClick(details["customerData"]["_id"])}>{details["requestServiceData"]["firstName"]} {details["requestServiceData"]["lastName"]}</span> : null}
                                        </td>);
                                    } else if (item.dataIndex === "customerEmail") {
                                        col.push(<td>
                                            {details["customerData"] ? details["customerData"]["email"] : null}
                                        </td>);
                                    } else if (item.dataIndex === "isOneFitPlusDownloadbyVendor") {
                                        col.push(<td>
                                            {details["customerData"] && details["customerData"]["isOneFitPlusDownloadbyVendor"] == true && "True" || "False"}
                                        </td>);
                                    } else if (item.dataIndex === "isFitWarzDownloadbyVendor") {
                                        col.push(<td>
                                            {details["customerData"] && details["customerData"]["isFitWarzDownloadbyVendor"] == true && "True" || "False"}
                                        </td>);
                                    } else if (item.dataIndex === "isTrainerAssigned") {
                                        col.push(<td>
                                            {details["customerData"] && details["customerData"]["isTrainerAssigned"] == true && "True" || "False"}
                                        </td>);
                                    } else if (item.dataIndex === "isCustomerAcceptedFee") {
                                        col.push(<td>
                                            {details["isCustomerAcceptedFee"] == true && "True" || "False"}
                                        </td>);
                                    } else if (item.dataIndex === "customerPhNO") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["mobileNo"] : null}
                                        </td>);
                                    } else if (item.dataIndex === "customerAltPhNO") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["alternatePhoneNo"] : null}
                                        </td>);
                                    } else if (item.dataIndex === "customerAddress") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["mailingStreet"] : null}
                                        </td>);
                                    } else if (item.dataIndex === "city") {
                                        col.push(
                                            <td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]['city'] : null}</td>);
                                    } else if (item.dataIndex === "state") {
                                        col.push(
                                            <td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]["state"] : null}</td>);
                                    } else if (item.dataIndex === "pincode") {
                                        col.push(
                                            <td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]["pincode"] : null}</td>);
                                    } else if (item.dataIndex === "remark") {
                                        col.push(<td><span className="cm_table_three_dots"
                                                           onClick={() => this.openRemarkView(details["remark"])}> {details["remark"] ? details["remark"] : null} </span>
                                        </td>)
                                    } else if (item.dataIndex === "images") {
                                        col.push((details && ((details.afterImages && details.afterImages.length > 0) || (details.beforeImages && details.beforeImages.length > 0))) ?
                                            <td className="cm_no_wrap">
                                                <button className="btn btn-info btn-sm"
                                                        onClick={() => this.openImageVideoView(details)}>View
                                                </button>
                                            </td> : (details && ((details.afterImage) || (details.beforeImage))) ?
                                                <td className="cm_no_wrap">
                                                    <button className="btn btn-info btn-sm"
                                                            onClick={() => this.openImageVideoView(details)}>View
                                                    </button>
                                                </td> : <td>N/A</td>)
                                    } else if (item.dataIndex === "brand") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["company"] : null}
                                        </td>);
                                    } else if (item.dataIndex === "model") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["modelNo"] : null}
                                        </td>);
                                    } else if (item.dataIndex === "vendorServiceCharges") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["pincodeData"]["pincode"] : 0}
                                        </td>);
                                    } else if (item.dataIndex === "additionalCharges") {
                                        col.push(<td>
                                            {details["additionalCharges"] ? (() => {
                                                const x = details["additionalCharges"]["addiChargesForSpareParts"];
                                                const y = details["additionalCharges"]["addiChargesForTransport"];
                                                const z = details["additionalCharges"]["addiChargesForCourier"];
                                                const sum = x + y + z;
                                                return (<p>{sum}</p>);
                                            })() : ""}
                                        </td>);
                                    } else if (item.dataIndex === "additionalChargesByAdmin") {
                                        col.push(<td>
                                            {details["additionalChargesByAdmin"] ? (() => {
                                                const x = details["additionalChargesByAdmin"]["addiChargesForSpareParts"];
                                                const y = details["additionalChargesByAdmin"]["addiChargesForTransport"];
                                                const z = details["additionalChargesByAdmin"]["addiChargesForCourier"];
                                                const sum = x + y + z;
                                                return (<p>{sum}</p>);
                                            })() : ""}
                                        </td>);
                                    }else if (item.dataIndex === "additionalChargesOnAgreed") {
                                        col.push(<td>
                                            {details["additionalChargesOnAgreed"] ? (() => {
                                                const x = details["additionalChargesOnAgreed"]["addiChargesForSpareParts"];
                                                const y = details["additionalChargesOnAgreed"]["addiChargesForTransport"];
                                                const z = details["additionalChargesOnAgreed"]["addiChargesForCourier"];
                                                const sum = x + y + z;
                                                return (<p>{sum}</p>);
                                            })() : ""}
                                        </td>);
                                    } else if (item.dataIndex === "paymentCurrentStatus") {
                                        col.push(<td>
                                            {
                                                (() => {
                                                    const x = details["additionalChargesPaymentStatus"];
                                                    let mmm = '';
                                                    if(x === 1){
                                                        mmm = "Auto Accepted"
                                                    }else if (x ===2){
                                                        mmm = "Accepted"
                                                    }else if (x===3){
                                                        mmm = "Rejected"
                                                    }else {
                                                        mmm = "Pending"
                                                    }
                                                    return (<p>{mmm}</p>);
                                                })()
                                            }

                                        </td>);
                                    } else if (item.dataIndex === "paymentStatus") {
                                        col.push(<td>
                                            <button className="btn btn-outline-dark btn-sm"
                                                    onClick={() => onClickAccept(details)}
                                                    disabled={details["additionalChargesPaymentStatus"] === 2
                                                        || details["additionalChargesPaymentStatus"] === 1
                                                        || (details["additionalCharges"]["addiChargesForSpareParts"]
                                                            + details["additionalCharges"]["addiChargesForTransport"]
                                                            + details["additionalCharges"]["addiChargesForCourier"])<1}>
                                                Accept
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                className="btn btn-outline-dark btn-sm"
                                                onClick={() => onClickReject(details)}
                                                disabled={details["additionalChargesPaymentStatus"] === 3
                                                    || (details["additionalCharges"]["addiChargesForSpareParts"]
                                                        + details["additionalCharges"]["addiChargesForTransport"]
                                                        + details["additionalCharges"]["addiChargesForCourier"])<1}>
                                                Reject
                                            </button>

                                        </td>);
                                    } else if (item.dataIndex === "spareReceived") {
                                        col.push(<td>
                                            {details["requestServiceData"] ? details["requestServiceData"]["spareReceived"] : null}
                                        </td>);
                                    } else if ((item.dataIndex === "noteData.notes_unread_count") && (adminRole != 7)) {
                                        col.push(<td>
                                            <button className="btn btn-dark btn-sm"
                                                    onClick={() => handleAddProductJobNotesPopup(details)}>
                                                Add
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                className={details['noteData'] && details['noteData']['notes_unread_count'] > 0 ? 'btn btn-sm btn-success' : 'btn btn-sm btn-dark'}
                                                onClick={() => handleShowProductJobNotesPopup(details)}>
                                                View {details["noteData"] && details["noteData"]["notes_unread_count"] > 0 ? '(' + details["noteData"]["notes_unread_count"] + ')' : null}
                                            </button>
                                        </td>);
                                    } else if ((item.dataIndex === "internalNoteData.notes_unread_count")) {
                                        col.push(<td>
                                            <button className="btn btn-dark btn-sm"
                                                    onClick={() => handleAddProductJobNotesPopup(details, 'internalNotes')}>
                                                Add
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                className={details['internalNoteData'] && details['internalNoteData']['notes_unread_count'] > 0 ? 'btn btn-sm btn-success' : 'btn btn-sm btn-dark'}
                                                onClick={() => handleShowProductJobNotesPopup(details, 'internalNotes')}>
                                                View {details["internalNoteData"] && details["internalNoteData"]["notes_unread_count"] > 0 ? '(' + details["internalNoteData"]["notes_unread_count"] + ')' : null}
                                            </button>
                                        </td>);
                                    } else if (item.dataIndex != "action") {
                                        if (adminRole == 7 && item.role == false) {

                                        } else {
                                            col.push(<td><span className="cm_table_three_dots">
                                                                            {item.parentDataIndex ? details[item.parentDataIndex] && getValue(item.type, details[item.parentDataIndex][item.dataIndex]) || "" : item.dataIndex == "status" ? statusOptions.find(o => o.value === details[item.dataIndex]) && statusOptions.find(o => o.value === details[item.dataIndex]).label : getValue(item.type, details[item.dataIndex])}</span>
                                            </td>)
                                        }
                                    } else if (item.dataIndex === "action" && adminRole != 7) {
                                        col.push(<td className="cm_no_wrap">
                                            <button className="btn btn-dark btn-sm"
                                                    onClick={() => this.handleAddProductJobPopup(details)}>Edit
                                            </button>

                                            &nbsp;&nbsp;
                                            <button className="btn btn-danger btn-sm"
                                                    onClick={(e) => this.openDeleteProductJobPopup(e, details)}>Delete
                                            </button>

                                        </td>)
                                    }
                                    return col

                                })}
                            </tr>)
                        })}
                        </tbody>
                    </table>
                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalProductJob}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                    />

                    {!productJobList.length ?
                        <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"}
                            <span>{search}</span></h3> : null}
                </div>

                {(selectedJobId) && < Card className="mt-5">
                    <Card.Header>
                        <h4 className="cm_page_heading">Service Incident Manangement</h4>
                    </Card.Header>
                    <Card.Body>
                        <ProductServiceIncident
                            adminRole={adminRole}
                            loader={true}
                            requestId={requestId}
                            jobId={selectedJobId}/>
                    </Card.Body>
                </Card>}
            </div>
        </div>


        <PopUpModal isOpen={showHideExport} closeModal={() => {
            closeExportModal()
        }} submit={exportHandler} message={`Are you sure? You want to export`} submitTitle="Export"/>
        <PopUpModal isOpen={statusPopup} closeModal={closeStatusPopup} submit={changeParticularJobStatus}
                    message={`Are you sure to change the status of job`} submitTitle="Update Status"/>

        <Popup open={showAddProductJobNotesPopup} closeOnDocumentClick={false} onClose={closeModal}>
            <div className="cm_modal">
                <span className="cm_modal_close" onClick={closeModal}>&times;</span>
                <h3 className="text-center">Add Note</h3>
                <div className="msg_box">

                    <Form onSubmit={addJobNoteAction}>
                        <div className='row'>
                            <FormGroup className='col-12'>
                                <FormLabel>Message {isFoundErrorNoteMessage ?
                                    <span className='error_message'> Please enter message </span> : ''} </FormLabel>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Message"
                                    value={state.noteMessage}
                                    name='noteMessage'
                                    onChange={handleChange}
                                    required
                                    rows={3}
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center">
                            <Button variant="danger" onClick={closeModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit" disabled={notesLoader}>
                                {notesLoader ? 'Wait' : 'Add'}
                            </Button>
                        </Row>
                    </Form>
                </div>
            </div>
        </Popup>
        <Popup open={showProductJobNotesPopup} closeOnDocumentClick={false} onClose={closeModal}>
            <div className="cm_modal">
                <span className="cm_modal_close" onClick={closeModal}>&times;</span>
                <h3 className="text-center">All Notes</h3>
                <div className="msg_box">

                    {jobNoteLists && jobNoteLists.map(note => <div className="msg_card">
                        <h6>{note.notedBy[0] && note.notedBy[0].firstName ? `${note.notedBy[0].firstName} ${note.notedBy[0].lastName}` : ''}</h6>
                        <time>{moment(note.created).format('Do MMM YYYY H:mm')} </time>
                        <span className={`msg_status review'`}> {note.state} </span>
                        <p className='text-break'>{note.content}</p>
                    </div>)}
                </div>
            </div>
        </Popup>

        <Popup open={showAcceptPaymentPopup} closeOnDocumentClick={false} onClose={closeShowAcceptPaymentPopup}>
            <div className="cm_modal cm_modal_hw">
                <span className="cm_modal_close" onClick={closeShowAcceptPaymentPopup}>&times;</span>
                <h3 className="text-center">Accept Payment </h3>

                <div className="container">
                    <div className="container my-5">

                        <div className="form-check mt-4">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                   onClick={event => handleAddiChargesSettlement(event)}/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Are you settling amount with Vendor?
                            </label>
                        </div>

                        <div className='row justify-content-center mt-4'>
                            <div className='col-4'>
                                <h6>Transport - </h6>
                            </div>

                            <div className="col">
                                <input type="text" className="form-control input-sm"
                                       name='transport'
                                       id="exampleFormControlInput1"
                                       placeholder="Transport"
                                       inputMode={"numeric"}
                                       value={addiChargesSettle.transport}
                                       onChange={handleChangeAddiSettle}
                                       disabled={!enableAddiInputBox}/>
                            </div>

                        </div>
                        <div className='row justify-content-center mt-2'>
                            <div className='col-4'>
                                <h6>Courier - </h6>
                            </div>

                            <div className="col">
                                <input type="text" className="form-control input-sm"
                                       name='courier'
                                       id="exampleFormControlInput1"
                                       placeholder="Courier"
                                       inputMode={"numeric"}
                                       value={addiChargesSettle.courier}
                                       onChange={handleChangeAddiSettle}
                                       disabled={!enableAddiInputBox}/>
                            </div>

                        </div>

                        <div className='row justify-content-center mt-2'>
                            <div className='col-4'>
                                <h6>Spare Parts- </h6>
                            </div>

                            <div className="col">
                                <input type="text" className="form-control input-sm"
                                       name='spareparts'
                                       id="exampleFormControlInput1"
                                       placeholder="Spare Parts"
                                       inputMode={"numeric"}
                                       value={addiChargesSettle.spareparts}
                                       onChange={handleChangeAddiSettle}
                                       disabled={!enableAddiInputBox}/>
                            </div>
                        </div>
                    </div>

                    <div className="msg_box mb-5">
                        <Form >
                            <div className='row'name ="">
                                <FormGroup className='col-12'>
                                    <FormLabel>Message {isFoundErrorNoteMessage ?
                                        <span className='error_message'> Please enter message </span> : ''} </FormLabel>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Message"
                                        value={addiChargesSettle.paymentReason}
                                        name='paymentReason'
                                        onChange={handleChangeAddiSettle}
                                        required
                                        rows={3}
                                    />
                                </FormGroup>
                            </div>
                        </Form>
                    </div>

                    <Row className="justify-content-center">
                        <Button variant="danger" onClick={closeShowAcceptPaymentPopup}>Cancel</Button>
                        <Button className="ml-2" variant="success" type="submit" disabled={notesLoader}
                                onClick={handleAcceptButtonClickOfPopup}>
                            {notesLoader ? 'Wait' : 'Accept'}
                        </Button>
                    </Row>
                </div>
            </div>
        </Popup>


        <Popup open={showRejectPaymentPopup} closeOnDocumentClick={false} onClose={closeShowRejectPaymentPopup}>
            <div className="cm_modal cm_modal_hw">
                <span className="cm_modal_close" onClick={closeShowRejectPaymentPopup}>&times;</span>
                <h3 className="text-center">Reject Payment </h3>

                <div className="container">
                    <div className="container my-5">

                        <div className='row justify-content-center mt-4'>
                            <div className='col-4'>
                                <h6>Transport - </h6>
                            </div>

                            <div className="col">
                                <input type="text" className="form-control input-sm"
                                       name='transport'
                                       id="exampleFormControlInput1"
                                       placeholder="Transport"
                                       inputMode={"numeric"}
                                       value={addiChargesReject.transport}
                                       onChange={handleChangeAddiRejection}
                                       disabled={true}/>
                            </div>

                        </div>
                        <div className='row justify-content-center mt-2'>
                            <div className='col-4'>
                                <h6>Courier - </h6>
                            </div>

                            <div className="col">
                                <input type="text" className="form-control input-sm"
                                       name='courier'
                                       id="exampleFormControlInput1"
                                       placeholder="Courier"
                                       inputMode={"numeric"}
                                       value={addiChargesReject.courier}
                                       onChange={handleChangeAddiRejection}
                                       disabled={true}/>
                            </div>

                        </div>

                        <div className='row justify-content-center mt-2'>
                            <div className='col-4'>
                                <h6>Spare Parts- </h6>
                            </div>

                            <div className="col">
                                <input type="text" className="form-control input-sm"
                                       name='spareparts'
                                       id="exampleFormControlInput1"
                                       placeholder="Spare Parts"
                                       inputMode={"numeric"}
                                       value={addiChargesReject.spareparts}
                                       onChange={handleChangeAddiRejection}
                                       disabled={true}/>
                            </div>
                        </div>
                    </div>

                    <div className="msg_box mb-5">
                        <Form >
                            <div className='row'name ="">
                                <FormGroup className='col-12'>
                                    <FormLabel>Rejection Reason {isFoundErrorNoteMessage ?
                                        <span className='error_message'> Please enter message </span> : ''} </FormLabel>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Message"
                                        value={addiChargesReject.paymentReason}
                                        name='paymentRejectReason'
                                        onChange={handleChangeAddiRejection}
                                        required
                                        rows={3}
                                    />
                                </FormGroup>
                            </div>
                        </Form>
                    </div>

                    <Row className="justify-content-center">
                        <Button variant="danger" onClick={closeShowRejectPaymentPopup}>Cancel</Button>
                        <Button className="ml-2" variant="success" type="submit" disabled={notesLoader}
                                onClick={handleRejectButtonClickOfPopup}>
                            {notesLoader ? 'Wait' : 'Reject'}
                        </Button>
                    </Row>
                </div>
            </div>
        </Popup>

    </HOC>);
};

export default GetJobsForReport;