import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { editGymEquipment, getAllEquipments } from '../../config/services/commercialService';
import { Button, Modal, FormControl, Form, Row, FormGroup, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';

const EditGymEquipmentModal = (props) => {
    const { showModal, hideModal, fetchGymEquipments, gymEqp } = props;
    const [serialNo, setSerialNo] = useState(gymEqp.serialNo || "");
    const [model, setModel] = useState(gymEqp.equipmentDetails && gymEqp.equipmentDetails.model ? {label: gymEqp.equipmentDetails.model, value: gymEqp.equipmentDetails._id} : "");
    const [purchaseDate, setPurchaseDate] = useState(gymEqp.purchaseDate ? moment(gymEqp.purchaseDate).format('YYYY-MM-DD') : "");
    const [deliveryDate, setDeliveryDate] = useState(gymEqp.deliveryDate ? moment(gymEqp.deliveryDate).format('YYYY-MM-DD') : "");
    const [installationDate, setInstallationDate] = useState(gymEqp.installationDate ? moment(gymEqp.installationDate).format('YYYY-MM-DD') : "");
    const [warrantyExpiry, setWarrantyExpiry] = useState(gymEqp.warrantyExpiryDate ? moment(gymEqp.warrantyExpiryDate).format('YYYY-MM-DD') : "");
    const [equipmentsList, setEquipmentsList] = useState("");

    useEffect(() => {
        fetchEquipmentsList();
    }, []);

    const fetchEquipmentsList = () => {
        const params = { pageNo: 1, pageSize: 1000 };
        getAllEquipments({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    const list = data.result && data.result.data ? data.result.data.map((eqp) => ({label: eqp.model, value: eqp._id})) : [];
                    setEquipmentsList(list);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handleValidation = () => {
        if(!model) {
            toast.error("Please select Model");
            return false;
        }

        return true;
    };

    const formSubmission = (event) => {
        event.preventDefault();

        const validationFlag = handleValidation();
        if(validationFlag) {
            const params = {
                gymEquipmentId: gymEqp._id,
                serialNo: serialNo,
                equipmentId: model.value,
            };
            
            if(purchaseDate) params.purchaseDate = moment(purchaseDate).format('MM-DD-YYYY');
            if(deliveryDate) params.deliveryDate = moment(deliveryDate).format('MM-DD-YYYY');
            if(installationDate) params.installationDate = moment(installationDate).format('MM-DD-YYYY');
            if(warrantyExpiry) params.warrantyExpiryDate = moment(warrantyExpiry).format('MM-DD-YYYY');

            editGymEquipment(params)
                .then(res => {
                    const response = res.data;

                    if (response.statusCode == 1) {
                        const data = response.responseData;
                        fetchGymEquipments();
                        toast.success(data.message);
                        hideModal();
                    } else if (response.statusCode == 0) {
                        toast.error(response.error.errorMessage)
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
    };

    return (
        <div className="main-wrapper">
            <Modal show={showModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Gym Equipment</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Form onSubmit={(e) => formSubmission(e)}>
                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Serial Number</FormLabel>
                                <FormControl
                                    value={serialNo}
                                    name="serialNo"
                                    onChange={(e) => setSerialNo(e.target.value)}
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Model</FormLabel>
                                <Select
                                    onChange={(e) => setModel(e)}
                                    options={equipmentsList}
                                    value={model ? model : ""}
                                />
                            </FormGroup>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Purchase Date</FormLabel>
                                <FormControl
                                    value={purchaseDate}
                                    name="purchaseDate"
                                    onChange={(e) => setPurchaseDate(e.target.value)}
                                    type="date"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Delivery Date</FormLabel>
                                <FormControl
                                    value={deliveryDate}
                                    name="deliveryDate"
                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                    type="date"
                                />
                            </FormGroup>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Installation Date</FormLabel>
                                <FormControl
                                    value={installationDate}
                                    name="installationDate"
                                    onChange={(e) => setInstallationDate(e.target.value)}
                                    type="date"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Warranty Expiry</FormLabel>
                                <FormControl
                                    value={warrantyExpiry}
                                    name="warrantyExpiry"
                                    onChange={(e) => setWarrantyExpiry(e.target.value)}
                                    type="date"
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center" style={{marginTop: "30px"}}>
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EditGymEquipmentModal;