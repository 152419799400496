import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import { POST, userAlreadyExist } from '../../config/services/adminService';
import { ReactComponent as Logo } from '../../assets/images/cult-logo.svg'
import { LoaderSvg } from '../Reusable'
import axios from 'axios';
import { withRouter } from "react-router";
import _ from 'lodash';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            mobileNo: '',
            password: '',
            emailErrorMsg: '',
            mobileNoErrorMsg: '',
            passwordErrorMsg: '',
            errors: false,
            Loader: false,
            isShowPassword: false,
            _statusCode: '',
            toggleLogin: false,
            userName: '',
            validateMobile: '^[6-9]\d{9}$',
            validatePassword: "^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$",
            isEmail: false,
            userAlreadyExisting: false,
            showScssesMsg:false,
            weekPasswordMsg: ''
        }

        this.handleValidetion = this.handleValidetion.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ShowPassword = this.ShowPassword.bind(this);
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    validatPhoneNo = (phone) => {
        return String(phone)
            .match(
                /^[6-9]\d{9}$/
            );
    }

     handleUser = (event) => {
        this.setState({
            userName: event.target.value
        })

        if (this.validateEmail(event.target.value)) {
            this.setState({
                email: event.target.value,
                errors: false,
                _statusCode: '',
                isEmail: true
            })
        } else if (this.validatPhoneNo(event.target.value)) {
            this.setState({
                mobileNo: event.target.value,
                errors: false,
                _statusCode: '',
                isEmail: false
            })
        }else{
            console.log('email or phone number not valid');
        }

    }

    handlePassword = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    handleToggleLogin = (event) => {
        this.setState({
            toggleLogin: event
        })
    }

    ShowPassword() {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }

    handleValidetion() {
        const { password, userName } = this.state
        let validate = true;
        let validatePassword = password;


        if (userName === '' || userName === undefined) {
            console.log('validate empty', userName)
            validate = false;
            this.setState({
                userNameErrorMsg: 'Please enter email or phone number'
            })
        } else {
            if (this.validateEmail(userName)) {
                console.log('validate email', userName)
                // this.userExist()
                this.setState({
                    userNameErrorMsg: '',
                })
            } else if (this.validatPhoneNo(userName)) {
                console.log('validate phone', userName)
                // this.userExist()
                this.setState({
                    userNameErrorMsg: '',
                })
            } else {
                validate = false;
                console.log('else')
                this.setState({
                    userNameErrorMsg: 'Please Enter a valid email or phone Number',
                })
            }
        }

        if (validatePassword === '' || validatePassword === undefined) {
            validate = false;
            this.setState({
                passwordErrorMsg: 'Enter Password',
            })
        } else {
            this.setState({
                passwordErrorMsg: ''
            })
        }
        return validate
    }

    handleSubmit(e) {
        const { email, password, userAlreadyExisting, isEmail, userName } = this.state
        e.preventDefault();
        this.setState({ Loader: true })
        if (this.handleValidetion()) {
            // console.log('isEmail---', isEmail)
            // console.log('email', email)
            // console.log('mobileNo', mobileNo)
            let obj;
            if (isEmail) {
                //     console.log('isEmail====', isEmail)
                obj = {
                    email: userName,
                    password: password,
                    role: 1
                }
            } else {
                //     console.log('mobile====', isEmail)
                obj = {
                    mobileNo: userName,
                    password: password,
                    role: 1
                }
            }
                POST(obj)
                    .then(res => {
                        console.log('test', res)
                        if (res.data.statusCode == 1) {
                            let { result, accessToken } = res.data.responseData
                            localStorage.setItem('otpToken', accessToken)
                            this.props.history.push('/verify-otp')
                            
                            if (localStorage.getItem('accessToken')) {
                                window.location.href = "/dashboard";
                            }
                        } else if(res.data.error.errorCode == 8 || res.data.error.errorCode == 10 || res.data.error.errorCode == 7 || res.data.error.errorCode == 113 || res.data.error.errorCode == 129) {
                            this.setState({ errors: true, Loader: false, _statusCode: res.data.error.errorMessage })
                        }else{
                            this.redirectToForgotPassword(res.data.error.errorMessage +'Your password is not strong, Please make it strong');
                        }
                    })

        } else {
            this.setState({ errors: true, Loader: false })
        }
    }

    redirectToForgotPassword=(msg)=>{
        this.setState({
            showScssesMsg: true,
            weekPasswordMsg: msg,
            Loader: false
        })
        setTimeout(()=>{
            this.closeModal();
            this.props.history.push('/forgot-password');
        },5000)
    }


    onFocus = event => {
        event.target.value = ''
     };

    closeModal = () => {
        this.setState({ showScssesMsg: false });
    }

    render() {

        const { _statusCode, toggleLogin, errors, email, emailErrorMsg, mobileNo, mobileNoErrorMsg, isShowPassword, password, passwordErrorMsg, userName } = this.state
        if (localStorage.getItem('accessToken')) {
            this.props.history.push('/dashboard')
        }

        return (
            <div className="login-wrapper">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-6 m-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title">
                                        <Logo />
                                        {_statusCode ? <p style={{ color: 'red' }}>{_statusCode}</p> : null}
                                    </div>
                                    <form onSubmit={this.handleSubmit} autoComplete="off">

                                        <div className="form-group">
                                            <input
                                                className={`form-control ${errors ? 'is-invalid' : ''}`}
                                                type="text"
                                                id='userName'
                                                name="userName"
                                                placeholder="Phone or email"
                                                onChange={this.handleUser}
                                                value={userName}
                                            />
                                            <div className="cm_alert_danger">{mobileNoErrorMsg}</div>
                                        </div>

                                        <div className="form-group cm_show_pass">
                                            <input
                                                className={`form-control ${errors ? 'is-invalid' : ''}`}
                                                type={isShowPassword ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Enter Password"
                                                onChange={this.handlePassword}
                                                value={password}
                                                autoComplete="new-password"
                                            />
                                            <div className="cm_alert_danger">{passwordErrorMsg}</div>
                                            <i
                                                className={`fa fa-eye${isShowPassword ? '' : '-slash'}`}
                                                onClick={this.ShowPassword}
                                                aria-hidden="true"></i>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col"><button className="btn btn-primary" type="submit">Login</button></div>
                                                <div className="col text-right pt-2"><Link to="/forgot-password">Forgot password</Link></div>
                                            </div>
                                        </div>

                                        {
                                            this.state.Loader
                                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                                : null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup
                    open={this.state.showScssesMsg}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        {/* <h3 className="text-center"> {this.state.email}</h3> */}
                        <p className="text-center">{this.state.weekPasswordMsg}</p>
                    </div>
                </Popup>

            </div>
        );
    }
}

export default withRouter(Login);
